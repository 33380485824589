<template>
  <div class="page">
    <el-container>
      <el-header>

        <Head :slide="slide" @logout="logout"></Head>
      </el-header>
      <el-main>
        <div class="">
          <ul class="bread-crumb">
            <li class="item item-1 fill" @click="$router.push('/pre/upload?id=' + id)">
              {{ $t('report.pre') + $t('report.upload') }}
            </li>
            <li class="item item-2 fill" @click="$router.push('/pre/scale?id=' + id)">
              {{ $t('report.pre') + $t('report.scale') }}
            </li>
            <li class="item item-3 fill" @click="$router.push('/pre/level?id=' + id)">
              {{ $t('report.pre') + $t('report.level') }}
            </li>
            <li class="item item-3 fill" @click="$router.push('/pre/measure?id=' + id)">
              {{ $t('report.pre') + $t('report.measure') }}
            </li>
            <li class="item item-3 fill">
              {{ $t('report.pre') + $t('report.install') }}
            </li>
            <li class="item item-4">
              {{ $t('report.pre') + $t('report.create') }}
            </li>

          </ul>

          <div class="main">
            <div class="left-side">

              <!-- <h2 class="content-title">
                        <div class="title">
                            <span>{{$t('report.step')}}</span>
                        </div>
                    </h2>
                     <ul class="step-list">
                        <li class="item">
                            1.{{$t('report.level')}}
                        </li>
                        <li class="item ">
                            2.{{$t('report.measure')}}
                        </li>
                        <li class="item active">
                            3.{{$t('report.install')}}
                        </li>
                    </ul> -->
              <el-form :label-width="$t('report.width')" class="form" label-position="left">
                <h2 class="content-title">
                  <div class="title">
                    <span>{{ $t('report.nearRing') }}:</span>
                  </div>
                </h2>

                <el-form-item :label="$t('report.type') + ':'">
                  <el-select v-model="nearType" :placeholder="$t('report.placeholder')">
                    <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('report.size') + ':'">
                  <el-select v-model="nearSize" :placeholder="$t('report.placeholder')">
                    <el-option v-for="item in nearSizeOptions" :key="item.value" :label="item.label + 'mm'"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>

                <h2 class="content-title">
                  <div class="title">
                    <span>"{{ $t('report.farRing') }}:</span>
                  </div>
                </h2>
                <el-form-item :label="$t('report.type') + ':'">
                  <el-select v-model="farType" :placeholder="$t('report.placeholder')">
                    <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('report.size') + ':'">
                  <el-select v-model="farSize" :placeholder="$t('report.placeholder')">
                    <el-option v-for="item in farSizeOptions" :key="item.value" :label="item.label + 'mm'"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>

            </div>
            <div class="canvas-box" style="position: relative">
              <div class="container"
                :style="{ 'width': oldWidth + 'px', 'height': oldHeight + 'px', 'overflow': 'auto' }">
                <v-stage :config="configKonva" ref="stage">
                  <v-layer ref="layer">
                    <v-image ref="xFront" :config="stageImage" />>
                    <v-line ref="lineReference" :config="configReference"></v-line>
                    <v-line ref="frontTopLineCenter" :config="frontTopLineCenter"></v-line>
                    <v-line ref="frontBottomLineCenter" :config="frontBottomLineCenter"></v-line>
                    <v-circle ref="frontSection" :config="frontSection"></v-circle>
                    <v-group ref="frontTopGroup" :config="configFrontTopTool">
                      <v-line ref="frontTopRing" :config="frontTopRing" @mouseleave="frontTopRingMouseLeave"
                        @mouseenter="frontTopRingMouseEnter($event)"></v-line>
                      <v-circle ref="frontTopDot" :config="frontTopDot" @mouseleave="frontTopDotMouseLeave"
                        @mouseenter="frontTopDotMouseEnter($event)"></v-circle>
                    </v-group>
                    <v-group ref="frontBottomGroup" :config="configFrontBottomTool">
                      <v-line ref="frontBottomRing" :config="frontBottomRing" @mouseleave="frontBottomRingMouseLeave"
                        @mouseenter="frontBottomRingMouseEnter($event)"></v-line>
                      <v-circle ref="frontBottomDot" :config="frontBottomDot" @mouseleave="frontBottomDotMouseLeave"
                        @mouseenter="frontBottomDotMouseEnter($event)"></v-circle>
                    </v-group>

                  </v-layer>
                </v-stage>

              </div>
              <Slide :slide="frontSlide" direction="front"></Slide>
              <Zoom class="zoom" @onIncrease="onIncrease" @onReset="onReset" @onReduce="onReduce"></Zoom>
            </div>
            <div class="canvas-box" style="position: relative">
              <div class="container"
                :style="{ 'width': oldWidth + 'px', 'height': oldHeight + 'px', 'overflow': 'auto' }">
                <v-stage :config="configKonva" ref="stage2">
                  <v-layer ref="layer2">
                    <v-image ref="xSide" :config="sideImage" />>
                    <v-line ref="lineReference" :config="configReference2"></v-line>
                    <v-line ref="sideTopLineCenter" :config="sideTopLineCenter"></v-line>
                    <v-line ref="sideBottomLineCenter" :config="sideBottomLineCenter"></v-line>
                    <v-circle ref="sideSection" :config="sideSection"></v-circle>
                    <v-group ref="sideTopGroup" :config="configSideTopTool">
                      <v-line ref="sideTopRing" :config="sideTopRing"></v-line>
                      <v-circle ref="sideTopDot" :config="sideTopDot"></v-circle>
                    </v-group>
                    <v-group ref="sideBottomGroup" :config="configSideBottomTool">
                      <v-line ref="sideBottomRing" :config="sideBottomRing"></v-line>
                      <v-circle ref="sideBottomDot" :config="sideBottomDot"></v-circle>
                    </v-group>
                  </v-layer>
                </v-stage>

              </div>
              <Slide :slide="sideSlide" direction="side"></Slide>
              <Zoom class="zoom" @onIncrease="onIncrease" @onReset="onReset" @onReduce="onReduce"></Zoom>
            </div>

            <div class="right-side">


              <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="form" label-position="left">
                <div class="green">
                  <h2 class="content-title">
                    <div class="title green">
                      <span>{{ $t('report.nearRing') }}</span>
                    </div>
                  </h2>
                  <el-form-item class="green" label-width="150px" :label="$t('report.param13') + '(mm):'">
                    <el-input :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.top.frontDistance"></el-input>
                  </el-form-item>
                  <el-form-item class="green" label-width="auto" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.top.frontDir">
                      <el-radio class="radio" label="0">{{ $t('report.param14') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param15') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item class="green" label-width="150px" :label="$t('report.param16') + '(mm):'">
                    <el-input :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.top.sideDistance"></el-input>
                  </el-form-item>
                  <el-form-item class="green" label-width="auto" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.top.sideDir">
                      <el-radio class="radio" label="0">{{ $t('report.param17') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param18') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item class="green" label-width="150px" :label="$t('report.param19') + '(mm):'">
                    <el-input :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.top.startDistance"></el-input>
                  </el-form-item>
                </div>
                <div class="yellow">
                  <h2 class="content-title">
                    <div class="title yellow">
                      <span>{{ $t('report.farRing') }}</span>
                    </div>
                  </h2>
                  <el-form-item class="yellow" label-width="150px" :label="$t('report.param13') + '(mm):'">
                    <el-input :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.bottom.frontDistance"></el-input>
                  </el-form-item>
                  <el-form-item class="yellow" label-width="auto" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.bottom.frontDir">
                      <el-radio class="radio" label="0">{{ $t('report.param14') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param15') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item class="yellow" label-width="150px" :label="$t('report.param16') + '(mm):'">
                    <el-input :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.bottom.sideDistance"></el-input>
                  </el-form-item>
                  <el-form-item class="yellow" label-width="auto" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.bottom.sideDir">
                      <el-radio class="radio" label="0">{{ $t('report.param17') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param18') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item class="yellow" label-width="150px" :label="$t('report.param19') + '(mm):'">
                    <el-input :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.bottom.startDistance"></el-input>
                  </el-form-item>
                </div>


                <div :class="['lock', { 'lock-fill': lock }]" @click="lock = !lock">
                  <img :src="lock ? require('../../assets/img/ziyuanxhdpi.png') : require('../../assets/img/icon_6.png')"
                    alt="">
                  <span>{{ lock ? $t('report.unlock') : $t('report.lock') }}</span>
                </div>
                <div class="btn-submit" @click="dialogVisible = true;">{{ $t('report.createpre') }}</div>
                <div class="text-upload" @click="lastStep">{{ $t('report.lastStep') }}</div>
              </el-form>

            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog :title="$t('report.paramsConfirm2')" :visible.sync="dialogVisible" width="30%" center>
      <div class="dialog">
        <ul>
          <li>{{ $t('report.nearRing') }}</li>
          <li>
            {{ ruleForm.top.frontDir == '0' ? $t('report.param14') : $t('report.param15') }} {{ ruleForm.top.frontDistance
              +
              'mm' }}
          </li>
          <li>
            {{ ruleForm.top.sideDir == '0' ? $t('report.param17') : $t('report.param18') }} {{ ruleForm.top.sideDistance +
              'mm' }}
          </li>
          <li>
            {{ $t('report.param19') }} {{ ruleForm.top.startDistance + 'mm' }}
          </li>
        </ul>
        <ul>
          <li>{{ $t('report.farRing') }}</li>
          <li>
            {{ ruleForm.bottom.frontDir == '0' ? $t('report.param14') : $t('report.param15') }}
            {{ ruleForm.bottom.frontDistance + 'mm' }}
          </li>
          <li>
            {{ ruleForm.bottom.sideDir == '0' ? $t('report.param17') : $t('report.param18') }}
            {{ ruleForm.bottom.sideDistance + 'mm' }}
          </li>
          <li>
            {{ $t('report.param19') }} {{ ruleForm.bottom.startDistance + 'mm' }}
          </li>
        </ul>


      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('report.BackToRevise') }}</el-button>
        <el-button type="primary" @click="submit">{{ $t('report.ConfirmData') }}</el-button>
      </span>
    </el-dialog>
    <div class="popover" :style="popoverPosition" v-show="popoverShow">
      <div v-html="popoverContent"></div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
// import Konva from 'konva';
import Zoom from '../../components/Zoom';
export default {
  components: {
    Zoom
  },
  data () {
    return {
      popoverShow: false,
      popoverPosition: { top: 0, left: 0 },
      popoverContent: '',
      dialogVisible: false,
      id: '', //病例id 
      ruleForm: {
        top: {
          frontDistance: 0, //正位中心位移
          sideDistance: 0, //侧位中心位移
          startDistance: 0, //起点位移
          frontDir: '0', //正位位移方向
          sideDir: '0', //侧位位移方向
        },
        bottom: {
          frontDistance: 0,
          sideDistance: 0,
          startDistance: 0,
          frontDir: '0',
          sideDir: '0',
        },
      },
      typeOptions: [ //环类型选项
        {
          value: 0,
          label: this.$t('report.ring1')
        }, {
          value: 1,
          label: this.$t('report.ring2')
        }, {
          value: 2,
          label: this.$t('report.ring3')
        }
      ],
      nearSizeOptions: [], //环规格选项
      farSizeOptions: [],
      nearType: '', //近侧环类型
      farType: '', //远侧环类型
      nearSize: '', //近侧环规格
      farSize: '', //远侧环规格
      stage: null, //画布
      layer: null, //画笔
      stage2: null, //画布2
      layer2: null, //画笔2
      frontImageObj: new Image(), //正位初始图片对象
      sideImageObj: new Image(), //侧位初始图片对象
      oldWidth: document.documentElement.clientWidth * 0.32, // 画布初始宽度
      oldHeight: document.documentElement.clientHeight * 0.8, // 画布初始高度
      widthIn: document.documentElement.clientWidth * 0.32, // 当前画布宽度
      heightIn: document.documentElement.clientHeight * 0.8, // 当前画布高度
      widthIn2: document.documentElement.clientWidth * 0.32,
      heightIn2: document.documentElement.clientHeight * 0.8,
      xRay: null,  //正位图
      xRayS: null, //侧位图
      base: [],
      isConfirm: false,
      disabled: false,
      actual: 200, //参考线距离mm
      dist: 0, //参考线距离px
      frontTopTool: null, //正位上工具
      frontBottomTool: null, //正位下工具
      sideTopTool: null, //正位上工具
      sideBottomTool: null, //正位下工具
      multiple: 1,
      scale: 1, //缩放比例
      slide: '',  //患侧信息
      frontSlide: '0', // 正位患侧
      sideSlide: '0', // 侧位患侧
      ringLoaded: false, //固环加载完成
      lock: false //是否锁定
    }
  },
  watch: {
    nearSize (n, o) {
      if (this.nearSizeOptions.length) {
        let width = this.nearSizeOptions[n].label * this.base[0].proportion / 2;
        const frontTopDot = this.$refs.frontTopDot.getNode();
        const sideTopDot = this.$refs.sideTopDot.getNode();
        const frontCoordinates = JSON.parse(this.base[0].coordinates);
        const sideCoordinates = JSON.parse(this.base[1].coordinates);
        let frontTopRingK = -1 / this.getLinesSlope(frontCoordinates.topCenter[0], frontCoordinates.topCenter[1], frontCoordinates.topCenter[2], frontCoordinates.topCenter[3]);
        let sideTopRingK = -1 / this.getLinesSlope(sideCoordinates.topCenter[0], sideCoordinates.topCenter[1], sideCoordinates.topCenter[2], sideCoordinates.topCenter[3]);
        this.$refs.frontTopRing.getNode().points(this.getRingPoint(frontTopDot.x(), frontTopDot.y(), frontTopRingK, width));
        this.$refs.sideTopRing.getNode().points(this.getRingPoint(sideTopDot.x(), sideTopDot.y(), sideTopRingK, width));
        this.layer.draw();
        this.layer2.draw();
      }

    },
    farSize (n, o) {
      if (this.farSizeOptions.length) {
        let width = this.farSizeOptions[n].label * this.base[0].proportion / 2;
        const frontBottomDot = this.$refs.frontBottomDot.getNode();
        const sideBottomDot = this.$refs.sideBottomDot.getNode();
        const frontCoordinates = JSON.parse(this.base[0].coordinates);
        const sideCoordinates = JSON.parse(this.base[1].coordinates);
        let frontBottomRingK = -1 / this.getLinesSlope(frontCoordinates.bottomCenter[0], frontCoordinates.bottomCenter[1], frontCoordinates.bottomCenter[2], frontCoordinates.bottomCenter[3]);
        let sideBottomRingK = -1 / this.getLinesSlope(sideCoordinates.bottomCenter[0], sideCoordinates.bottomCenter[1], sideCoordinates.bottomCenter[2], sideCoordinates.bottomCenter[3]);
        this.$refs.frontBottomRing.getNode().points(this.getRingPoint(frontBottomDot.x(), frontBottomDot.y(), frontBottomRingK, width));
        this.$refs.sideBottomRing.getNode().points(this.getRingPoint(sideBottomDot.x(), sideBottomDot.y(), sideBottomRingK, width));
        this.layer.draw();
        this.layer2.draw();
      }

    },
    async nearType (n, o) {
      this.nearSizeOptions = await this.getSolidRing(n);
      if (this.nearSizeOptions.length) {
        let width = this.nearSizeOptions[0].label * this.base[0].proportion / 2;
        const frontTopDot = this.$refs.frontTopDot.getNode();
        const sideTopDot = this.$refs.sideTopDot.getNode();
        const frontCoordinates = JSON.parse(this.base[0].coordinates);
        const sideCoordinates = JSON.parse(this.base[1].coordinates);
        let frontTopRingK = -1 / this.getLinesSlope(frontCoordinates.topCenter[0], frontCoordinates.topCenter[1], frontCoordinates.topCenter[2], frontCoordinates.topCenter[3]);
        let sideTopRingK = -1 / this.getLinesSlope(sideCoordinates.topCenter[0], sideCoordinates.topCenter[1], sideCoordinates.topCenter[2], sideCoordinates.topCenter[3]);
        this.$refs.frontTopRing.getNode().points(this.getRingPoint(frontTopDot.x(), frontTopDot.y(), frontTopRingK, width));
        this.$refs.sideTopRing.getNode().points(this.getRingPoint(sideTopDot.x(), sideTopDot.y(), sideTopRingK, width));
        this.layer.draw();
        this.layer2.draw();
      }


    },
    async farType (n, o) {
      this.farSizeOptions = await this.getSolidRing(n);
      if (this.farSizeOptions.length) {
        let width = this.farSizeOptions[0].label * this.base[0].proportion / 2;
        const frontBottomDot = this.$refs.frontBottomDot.getNode();
        const sideBottomDot = this.$refs.sideBottomDot.getNode();
        const frontCoordinates = JSON.parse(this.base[0].coordinates);
        const sideCoordinates = JSON.parse(this.base[1].coordinates);
        let frontBottomRingK = -1 / this.getLinesSlope(frontCoordinates.bottomCenter[0], frontCoordinates.bottomCenter[1], frontCoordinates.bottomCenter[2], frontCoordinates.bottomCenter[3]);
        let sideBottomRingK = -1 / this.getLinesSlope(sideCoordinates.bottomCenter[0], sideCoordinates.bottomCenter[1], sideCoordinates.bottomCenter[2], sideCoordinates.bottomCenter[3]);
        this.$refs.frontBottomRing.getNode().points(this.getRingPoint(frontBottomDot.x(), frontBottomDot.y(), frontBottomRingK, width));
        this.$refs.sideBottomRing.getNode().points(this.getRingPoint(sideBottomDot.x(), sideBottomDot.y(), sideBottomRingK, width));
        this.layer.draw();
        this.layer2.draw();
      }

    }
  },
  computed: {
    //画布配置
    configKonva () {
      return {
        width: this.widthIn,
        height: this.heightIn,
        // draggable: !this.lock, //可拖拽
      }
    },
    //正位图配置
    stageImage () {
      return {
        x: this.configKonva.width / 2,
        y: this.configKonva.height / 2,
        rotation: 0, //旋转角度
        // draggable: !this.lock, //可拖拽
        image: this.frontImageObj,
        offset: {
          x: this.frontImageObj.width / 2,
          y: this.frontImageObj.height / 2
        },
      }
    },
    //侧位图配置
    sideImage () {
      return {
        x: this.widthIn2 / 2,
        y: this.heightIn / 2,
        rotation: 0, //旋转角度
        // draggable: !this.lock, //可拖拽
        image: this.sideImageObj,
        offset: {
          x: this.sideImageObj.width / 2,
          y: this.sideImageObj.height / 2
        },
      }
    },

    //参考线
    configReference () {
      return {
        points: [0, this.dist, this.configKonva.width, this.dist],
        stroke: '#FF0A0A',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [5 / this.scale, 5 / this.scale],
        // draggable: !this.lock,
        dragBoundFunc: function (pos) {
          return {
            x: this.absolutePosition().x,
            y: pos.y
          };
        }
      }
    },
    configReference2 () {
      return {
        points: [0, this.dist, this.widthIn2, this.dist],
        stroke: '#FF0A0A',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [5 / this.scale, 5 / this.scale],
        // draggable: !this.lock,

      }
    },

    //正位上骨骼工具
    configFrontTopTool () {
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          setTimeout(that.dotDragBound, 10)
          let points = that.$refs.frontTopLineCenter.getNode().points();
          let dot = that.$refs.frontTopDot.getNode();
          let ring = that.$refs.frontTopRing.getNode();
          that.sideTopTool.y(pos.y / that.scale);
          that.layer2.draw();
          return that.ringDragBound(points, dot, ring, pos);
        }
      }
    },

    //正位上骨骼工具-中线
    frontTopLineCenter () {
      return {
        points: this.ringLoaded ? this.$refs.frontTopLineCenter.getNode().points() : [],
        stroke: '#00BF99',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [10 / this.scale, 4 / this.scale],
      }
    },
    //正位上固环
    frontTopRing () {
      return {
        points: this.ringLoaded ? this.$refs.frontTopRing.getNode().points() : [],
        stroke: '#00BF99',
        strokeWidth: 8 / this.scale,

      }
    },
    frontTopDot () {
      return {
        x: 0,
        y: 0,
        radius: 6 / this.scale,
        fill: '#FFFFFF',
      }
    },
    //正位下骨骼工具
    configFrontBottomTool () {
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          setTimeout(that.dotDragBound, 10)
          let points = that.$refs.frontBottomLineCenter.getNode().points();
          let dot = that.$refs.frontBottomDot.getNode();
          let ring = that.$refs.frontBottomRing.getNode();
          that.sideBottomTool.y(pos.y / that.scale);
          that.layer2.draw();
          return that.ringDragBound(points, dot, ring, pos);
        }
      }
    },
    //正位下骨骼工具-中线
    frontBottomLineCenter () {
      return {
        points: this.$refs.frontBottomLineCenter ? this.$refs.frontBottomLineCenter.getNode().points() : [],
        stroke: '#FFFF00',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [10 / this.scale, 4 / this.scale],
      }
    },

    //正位下骨骼工具-下截面点
    frontSection () {
      return {
        x: 0,
        y: 0,
        radius: 6 / this.scale,
        fill: '#8000FF',
      }
    },
    //正位下固环
    frontBottomRing () {
      return {
        points: this.$refs.frontBottomRing ? this.$refs.frontBottomRing.getNode().points() : [],
        stroke: '#FFFF00',
        strokeWidth: 8 / this.scale,

      }
    },
    frontBottomDot () {
      return {
        x: 0,
        y: 0,
        radius: 6 / this.scale,
        fill: '#FFFFFF',
      }
    },
    //侧位上骨骼工具
    configSideTopTool () {
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          setTimeout(that.dotDragBound, 10);
          let points = that.$refs.sideTopLineCenter.getNode().points();
          let dot = that.$refs.sideTopDot.getNode();
          let ring = that.$refs.sideTopRing.getNode();
          that.frontTopTool.y(pos.y / that.scale);
          that.layer.draw();
          return that.ringDragBound(points, dot, ring, pos);
        }
      }
    },
    //侧位上骨骼工具-中线
    sideTopLineCenter () {
      return {
        points: this.ringLoaded ? this.$refs.sideTopLineCenter.getNode().points() : [],
        stroke: '#00BF99',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [10 / this.scale, 4 / this.scale],
      }
    },
    //侧位上固环
    sideTopRing () {
      return {
        points: this.ringLoaded ? this.$refs.sideTopRing.getNode().points() : [],
        stroke: '#00BF99',
        strokeWidth: 8 / this.scale,

      }
    },
    sideTopDot () {
      return {
        x: 0,
        y: 0,
        radius: 6 / this.scale,
        fill: '#FFFFFF',
      }
    },
    //侧位下骨骼工具
    configSideBottomTool () {
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          setTimeout(that.dotDragBound, 10);
          let points = that.$refs.sideBottomLineCenter.getNode().points();
          let dot = that.$refs.sideBottomDot.getNode();
          let ring = that.$refs.sideBottomRing.getNode();
          that.frontBottomTool.y(pos.y / that.scale);
          that.layer.draw();
          return that.ringDragBound(points, dot, ring, pos);
        }
      }
    },

    sideBottomLineCenter () {
      return {
        points: this.ringLoaded ? this.$refs.sideBottomLineCenter.getNode().points() : [],
        stroke: '#FFFF00',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [10 / this.scale, 4 / this.scale],
      }
    },
    //侧位下截面点
    sideSection () {
      return {
        x: 0,
        y: 0,
        radius: 6 / this.scale,
        fill: '#8000FF',
      }
    },
    //侧位下固环
    sideBottomRing () {
      return {
        points: this.ringLoaded ? this.$refs.sideBottomRing.getNode().points() : [],
        stroke: '#FFFF00',
        strokeWidth: 8 / this.scale,
      }
    },
    sideBottomDot () {
      return {
        x: 0,
        y: 0,
        radius: 6 / this.scale,
        fill: '#FFFFFF',
      }
    },
  },

  async created () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      let res = await this.$api.getReport({
        before_after: 0,
        id: this.id
      });
      if (res.code) {
        this.base = res.data.base;
        this.frontSlide = this.base[0].left_right ? JSON.parse(this.base[0].left_right).slide : this.slide;
        this.sideSlide = this.base[1].left_right ? JSON.parse(this.base[1].left_right).slide : this.slide;
        this.slide = res.data.case.slide;
        if (res.data.install && res.data.install.length) {
          this.nearType = Number(res.data.install[0].solid_type);
          this.farType = Number(res.data.install[1].solid_type);
          this.nearSizeOptions = await this.getSolidRing(this.nearType);
          this.farSizeOptions = await this.getSolidRing(this.farType);
          let nearIndex = this.nearSizeOptions.findIndex(val => { return val.id == res.data.install[0].solid_ring_id })
          let farIndex = this.farSizeOptions.findIndex(val => { return val.id == res.data.install[1].solid_ring_id })
          this.nearSize = nearIndex == -1 ? 0 : nearIndex;
          this.farSize = farIndex == -1 ? 0 : farIndex;
        } else {
          this.nearSizeOptions = await this.getSolidRing(this.nearType);
          this.farSizeOptions = await this.getSolidRing(this.farType);
        }
        //
        this.frontImageObj.src = this.$baseUrl + this.base[0].image;
        this.sideImageObj.src = this.$baseUrl + this.base[1].image;
        //
        const frontCoordinates = JSON.parse(this.base[0].coordinates);
        const sideCoordinates = JSON.parse(this.base[1].coordinates);
        const install = res.data.install;
        this.dist = frontCoordinates.dist ? frontCoordinates.dist : this.dist;
        this.multiple = frontCoordinates.multiple;
        //
        this.$refs.frontTopLineCenter.getNode().points(frontCoordinates.topCenter);
        this.$refs.frontBottomLineCenter.getNode().points(frontCoordinates.bottomCenter);
        this.$refs.sideTopLineCenter.getNode().points(sideCoordinates.topCenter);
        this.$refs.sideBottomLineCenter.getNode().points(sideCoordinates.bottomCenter);
        //
        this.$refs.frontSection.getNode().x(frontCoordinates.section.x);
        this.$refs.frontSection.getNode().y(frontCoordinates.section.y);
        this.$refs.sideSection.getNode().x(sideCoordinates.section.x);
        this.$refs.sideSection.getNode().y(sideCoordinates.section.y);
        //
        const frontTopDot = this.$refs.frontTopDot.getNode();
        const frontBottomDot = this.$refs.frontBottomDot.getNode();
        const sideTopDot = this.$refs.sideTopDot.getNode();
        const sideBottomDot = this.$refs.sideBottomDot.getNode();
        const initDist = 20 * this.base[0].proportion;
        const nearCoordinates = install.length && install[0].coordinates ? JSON.parse(install[0].coordinates) : false;
        const farCoordinates = install.length && install[1].coordinates ? JSON.parse(install[1].coordinates) : false;
        if (nearCoordinates && farCoordinates) {
          //还原
          frontTopDot.x(nearCoordinates.fDotX);
          frontTopDot.y(nearCoordinates.fDotY);
          frontBottomDot.x(farCoordinates.fDotX);
          frontBottomDot.y(farCoordinates.fDotY);
          sideTopDot.x(nearCoordinates.sDotX);
          sideTopDot.y(nearCoordinates.sDotY);
          sideBottomDot.x(farCoordinates.sDotX);
          sideBottomDot.y(farCoordinates.sDotY);
          this.scale = nearCoordinates.scale ? nearCoordinates.scale : 1
          this.lock = true; //锁定
        } else {
          //初始化
          frontTopDot.x(this.getPointX(frontCoordinates.topCenter[0], frontCoordinates.topCenter[1], frontCoordinates.topCenter[2], frontCoordinates.topCenter[3], this.dist - initDist));
          frontTopDot.y(this.dist - initDist);
          frontBottomDot.x(this.getPointX(frontCoordinates.bottomCenter[0], frontCoordinates.bottomCenter[1], frontCoordinates.bottomCenter[2], frontCoordinates.bottomCenter[3], this.$refs.frontSection.getNode().y() + initDist));
          frontBottomDot.y(this.$refs.frontSection.getNode().y() + initDist);
          sideTopDot.x(this.getPointX(sideCoordinates.topCenter[0], sideCoordinates.topCenter[1], sideCoordinates.topCenter[2], sideCoordinates.topCenter[3], this.dist - initDist));
          sideTopDot.y(this.dist - initDist);
          sideBottomDot.x(this.getPointX(sideCoordinates.bottomCenter[0], sideCoordinates.bottomCenter[1], sideCoordinates.bottomCenter[2], sideCoordinates.bottomCenter[3], this.$refs.sideSection.getNode().y() + initDist));
          sideBottomDot.y(this.$refs.sideSection.getNode().y() + initDist);
        }
        setTimeout(() => { this.toScale() }, 10)
        //
        let nearSize = this.nearSize ? this.nearSizeOptions[this.nearSize].label * this.base[0].proportion / 2 : 0;
        let farSize = this.farSize ? this.farSizeOptions[this.farSize].label * this.base[0].proportion / 2 : 0;
        //固环斜率
        let frontTopRingK = -1 / this.getLinesSlope(frontCoordinates.topCenter[0], frontCoordinates.topCenter[1], frontCoordinates.topCenter[2], frontCoordinates.topCenter[3]);
        let frontBottomRingK = -1 / this.getLinesSlope(frontCoordinates.bottomCenter[0], frontCoordinates.bottomCenter[1], frontCoordinates.bottomCenter[2], frontCoordinates.bottomCenter[3]);
        let sideTopRingK = -1 / this.getLinesSlope(sideCoordinates.topCenter[0], sideCoordinates.topCenter[1], sideCoordinates.topCenter[2], sideCoordinates.topCenter[3]);
        let sideBottomRingK = -1 / this.getLinesSlope(sideCoordinates.bottomCenter[0], sideCoordinates.bottomCenter[1], sideCoordinates.bottomCenter[2], sideCoordinates.bottomCenter[3]);

        this.$refs.frontTopRing.getNode().points(this.getRingPoint(frontTopDot.x(), frontTopDot.y(), frontTopRingK, nearSize));
        this.$refs.frontBottomRing.getNode().points(this.getRingPoint(frontBottomDot.x(), frontBottomDot.y(), frontBottomRingK, farSize));
        this.$refs.sideTopRing.getNode().points(this.getRingPoint(sideTopDot.x(), sideTopDot.y(), sideTopRingK, nearSize));
        this.$refs.sideBottomRing.getNode().points(this.getRingPoint(sideBottomDot.x(), sideBottomDot.y(), sideBottomRingK, farSize));
        this.dotDragBound();

        this.ruleForm.top.frontDistance = install.length && install[0].middle_dis ? install[0].middle_dis : '0';
        this.ruleForm.top.sideDistance = install.length && install[0].slide_dis ? install[0].slide_dis : '0';
        this.ruleForm.top.startDistance = install.length && install[0].offset_dis ? install[0].offset_dis : '0';
        this.ruleForm.top.frontDir = install.length && install[0].in_out_slide ? install[0].in_out_slide : '0';
        this.ruleForm.top.sideDir = install.length && install[0].before_after_slide ? install[0].before_after_slide : '0';

        this.ruleForm.bottom.frontDistance = install.length && install[1].middle_dis ? install[1].middle_dis : '0';
        this.ruleForm.bottom.sideDistance = install.length && install[1].slide_dis ? install[1].slide_dis : '0';
        this.ruleForm.bottom.startDistance = install.length && install[1].offset_dis ? install[1].offset_dis : '0';
        this.ruleForm.bottom.frontDir = install.length && install[1].in_out_slide ? install[1].in_out_slide : '0';
        this.ruleForm.bottom.sideDir = install.length && install[1].before_after_slide ? install[1].before_after_slide : '0';
        this.ringLoaded = true;

      }
    } else {
      this.$message.error("病例不存在");
      setTimeout(res => {
        this.$router.go(-1)
      }, 500)
    }
    //

  },
  mounted () {
    document.getElementsByClassName('right-side')[0].style.height = this.configKonva.height + 'px';
    document.getElementsByClassName('left-side')[0].style.height = this.configKonva.height + 'px';
    this.stage = this.$refs.stage.getNode();
    this.layer = this.$refs.layer.getNode();
    this.stage2 = this.$refs.stage2.getNode();
    this.layer2 = this.$refs.layer2.getNode();
    this.xRay = this.$refs.xFront.getNode();
    this.xRayS = this.$refs.xSide.getNode();
    this.frontTopTool = this.$refs.frontTopGroup.getNode();
    this.frontBottomTool = this.$refs.frontBottomGroup.getNode();
    this.sideTopTool = this.$refs.sideTopGroup.getNode();
    this.sideBottomTool = this.$refs.sideBottomGroup.getNode();
    let that = this;
    this.frontImageObj.onload = function (e) {
      // that.resizeImg(this);
      // that.zoom();
      that.xRay.rotation(that.base[0].angle)
      that.heightIn = that.xRay.height();
      that.widthIn = that.xRay.width();
    }
    this.sideImageObj.onload = function (e) {
      that.unifyImg(this);
      that.xRayS.rotation(that.base[1].angle)
      that.heightIn2 = that.xRayS.height();
      that.widthIn2 = that.xRayS.width();
      that.xRayS.y(that.xRayS.y() + Number(that.base[1].move))
      that.layer2.draw();
    }

    //监听事件改变鼠标样式
    this.frontTopTool.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
    });
    this.frontTopTool.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });
    this.frontBottomTool.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
    });
    this.frontBottomTool.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });
    this.sideTopTool.on('mouseenter', () => {
      this.stage2.container().style.cursor = 'pointer';
    });
    this.sideTopTool.on('mouseleave', () => {
      this.stage2.container().style.cursor = 'default';
    });
    this.sideBottomTool.on('mouseenter', () => {
      this.stage2.container().style.cursor = 'pointer';
    });
    this.sideBottomTool.on('mouseleave', () => {
      this.stage2.container().style.cursor = 'default';
    });
  },
  methods: {
    //同比比例
    unifyImg (image) {
      let proportion1 = this.base[0].proportion;
      let proportion2 = this.base[1].proportion;
      let unify = proportion1 / proportion2;
      image.height = image.height * unify;
      image.width = image.width * unify;
      this.isUnify = true;
      return image;
    },
    //正位患侧信息修改
    frontSlideChange () {
      this.frontSlide = this.frontSlide == '0' ? '1' : '0';
      this.saveSlide();
    },
    //侧位患侧信息修改
    sideSlideChange () {
      this.sideSlide = this.sideSlide == '0' ? '1' : '0';
      this.saveSlide();
    },
    //保存患侧信息
    saveSlide () {
      this.$api.saveLeftRight({
        id: this.id,
        before_after: 0,
        left_right: JSON.stringify([{ slide: this.frontSlide }, { slide: this.sideSlide }])
      })
    },
    //获取固环
    async getSolidRing (type) {
      let res = await this.$api.getSolidRing({ limit: 999, type });
      if (res.code) {
        let size = [];
        if (!res.data.length) {
          return [];
        }
        res.data.map((val, index) => {
          size.push({
            id: val.id,
            value: index,
            label: val.long
          })
        });
        return size;
      }
      this.$message.error('固环获取失败')
      return [];
    },
    ringDragBound (points, dot, ring, pos) {
      let ringLeftX = this.getPointX(points[0] * this.scale, points[1] * this.scale, points[2] * this.scale, points[3] * this.scale, ring.points()[3] * this.scale + pos.y) - dot.x() * this.scale;
      let ringRightX = this.getPointX(points[0] * this.scale, points[1] * this.scale, points[2] * this.scale, points[3] * this.scale, ring.points()[1] * this.scale + pos.y) - dot.x() * this.scale;
      let ringLeft = ringLeftX + ring.width() / 2 * this.scale;
      let ringRight = ringRightX - ring.width() / 2 * this.scale;
      let nPos = {
        x: pos.x > ringLeft ? ringLeft : pos.x < ringRight ? ringRight : pos.x,
        y: pos.y
      };
      return nPos;
    },
    //获取固环坐标
    getRingPoint (dotX, dotY, ringK, d) {
      let x1 = dotX + (d / Math.sqrt(Math.pow(ringK, 2) + 1));
      let y1 = dotY + (ringK * d / Math.sqrt(Math.pow(ringK, 2) + 1));
      let x2 = dotX - (d / Math.sqrt(Math.pow(ringK, 2) + 1));
      let y2 = dotY - (ringK * d / Math.sqrt(Math.pow(ringK, 2) + 1));
      return [x1, y1, x2, y2];
    },
    //获取中线坐标
    getPoint (x1, y1, x2, y2, x3, y3, x4, y4) {
      let topCenterX = (x1 + x2) / 2;
      let topCenterY = (y1 + y2) / 2;
      let bottomCenterX = (x3 + x4) / 2;
      let bottomCenterY = (y3 + y4) / 2;
      return [
        this.getPointX(topCenterX, topCenterY, bottomCenterX, bottomCenterY, -100),
        -100,
        topCenterX,
        topCenterY,
        bottomCenterX,
        bottomCenterY,
        this.getPointX(topCenterX, topCenterY, bottomCenterX, bottomCenterY, this.heightIn + 100),
        this.heightIn + 100,
      ]
    },
    getPointX (x1, y1, x2, y2, y = 0) {
      return (x2 - x1) / (y2 - y1) * (y - y1) + x1;
    },
    //画布放大
    onIncrease () {
      this.scale = this.scale / 0.9;
      this.toScale();
    },
    //恢复原尺寸
    onReset () {
      this.scale = 1;
      this.toScale();
    },
    //画布缩小
    onReduce () {
      this.scale = this.scale * 0.9;
      this.toScale();
    },
    toScale () {
      this.stage.width(this.widthIn * this.scale)
      this.stage.height(this.heightIn * this.scale)
      this.stage2.width(this.widthIn2 * this.scale)
      this.stage2.height(this.heightIn2 * this.scale)
      this.stage.scale({ x: this.scale, y: this.scale })
      this.stage2.scale({ x: this.scale, y: this.scale })
      this.layer.draw();
      this.layer2.draw();
    },
    zoom () {
      // this.stage.scale({x: this.multiple, y: this.multiple})
      this.xRay.width(this.frontImageObj.width * this.multiple);
      this.xRay.height(this.frontImageObj.height * this.multiple);
      this.xRayS.width(this.sideImageObj.width * this.multiple);
      this.xRayS.height(this.sideImageObj.height * this.multiple);
      this.xRay.offset({
        x: this.frontImageObj.width * this.multiple / 2,
        y: this.frontImageObj.height * this.multiple / 2
      });
      this.xRayS.offset({
        x: this.sideImageObj.width * this.multiple / 2,
        y: this.sideImageObj.height * this.multiple / 2
      });
      // this.actual = Math.floor(this.$refs.lineReference1.getNode().y() / this.base[0].proportion / this.multiple * 100) / 100;
      this.layer.draw();
      this.layer2.draw();
    },
    resizeImg (image) {
      let w = image.width; //图片宽度
      let h = image.height; //图片宽度
      let FitWidth = this.configKonva.width //画布宽度
      let FitHeight = this.configKonva.height //画布宽度
      // 当图片比预览区域小时不做任何改变
      if (w < FitWidth && h < FitHeight) {
        return false;
      }

      // 当实际图片比例大于预览区域宽高比例时
      if (image.width / image.height >= FitWidth / FitHeight) {
        //宽度超出 
        image.height = ((image.height * FitWidth) / image.width) / 2;
        image.width = FitWidth / 2;
      } else {
        //高度超出
        image.width = ((image.width * FitHeight) / image.height) / 2;
        image.height = FitHeight / 2;
      }
    },
    //求垂线坐标
    getVerticalPoint () {

    },
    dotDragBound () {
      let frontTopDot = this.$refs.frontTopDot.getNode();
      let frontBottomDot = this.$refs.frontBottomDot.getNode();
      let sideTopDot = this.$refs.sideTopDot.getNode();
      let sideBottomDot = this.$refs.sideBottomDot.getNode();
      //
      let frontTopLineCenter = this.$refs.frontTopLineCenter.getNode().points();
      let frontBottomLineCenter = this.$refs.frontBottomLineCenter.getNode().points();
      let sideTopLineCenter = this.$refs.sideTopLineCenter.getNode().points();
      let sideBottomLineCenter = this.$refs.sideBottomLineCenter.getNode().points();
      this.ruleForm.top.frontDistance = this.getDistFromPointToLine(frontTopDot.x() + this.frontTopTool.x(), frontTopDot.y() + this.frontTopTool.y(), frontTopLineCenter);
      this.ruleForm.top.sideDistance = this.getDistFromPointToLine(sideTopDot.x() + this.sideTopTool.x(), sideTopDot.y() + this.sideTopTool.y(), sideTopLineCenter);
      this.ruleForm.bottom.frontDistance = this.getDistFromPointToLine(frontBottomDot.x() + this.frontBottomTool.x(), frontBottomDot.y() + this.frontBottomTool.y(), frontBottomLineCenter);
      this.ruleForm.bottom.sideDistance = this.getDistFromPointToLine(sideBottomDot.x() + this.sideBottomTool.x(), sideBottomDot.y() + this.sideBottomTool.y(), sideBottomLineCenter);
      //
      this.ruleForm.top.startDistance = Math.floor(Math.abs(frontTopDot.y() + this.frontTopTool.y() - this.dist) / this.base[0].proportion * 100) / 100;
      this.ruleForm.bottom.startDistance = Math.floor(Math.abs(frontBottomDot.y() + this.frontBottomTool.y() - this.$refs.frontSection.getNode().y()) / this.base[0].proportion * 100) / 100;


    },
    //提交，下一步
    submit () {
      if (this.disabled) return false;
      this.disabled = true;
      this.dialogVisible = false;
      const dataTop = this.ruleForm.top;
      const dataBottom = this.ruleForm.bottom;
      let obj = [
        {
          solid_type: this.nearType,
          solid_ring_id: this.nearSizeOptions[this.nearSize].id,
          in_out_slide: dataTop.frontDir,
          middle_dis: dataTop.frontDistance,
          before_after_slide: dataTop.sideDir,
          slide_dis: dataTop.sideDistance,
          offset_dis: dataTop.startDistance,
          coordinates: {
            scale: this.scale,
            fDotX: this.$refs.frontTopDot.getNode().x() + this.frontTopTool.x(),
            fDotY: this.$refs.frontTopDot.getNode().y() + this.frontTopTool.y(),
            sDotX: this.$refs.sideTopDot.getNode().x() + this.sideTopTool.x(),
            sDotY: this.$refs.sideTopDot.getNode().y() + this.sideTopTool.y(),
          }
        }, {
          solid_type: this.farType,
          solid_ring_id: this.farSizeOptions[this.farSize].id,
          in_out_slide: dataBottom.frontDir,
          middle_dis: dataBottom.frontDistance,
          before_after_slide: dataBottom.sideDir,
          slide_dis: dataBottom.sideDistance,
          offset_dis: dataBottom.startDistance,
          coordinates: {
            scale: this.scale,
            fDotX: this.$refs.frontBottomDot.getNode().x() + this.frontBottomTool.x(),
            fDotY: this.$refs.frontBottomDot.getNode().y() + this.frontBottomTool.y(),
            sDotX: this.$refs.sideBottomDot.getNode().x() + this.sideBottomTool.x(),
            sDotY: this.$refs.sideBottomDot.getNode().y() + this.sideBottomTool.y(),
          }
        }
      ]
      this.$api.saveInstallBefore({
        id: this.id,
        obj: JSON.stringify(obj)
      }).then(res => {
        //术前报告
        // this.disabled = false;
        if (res.code) {
          return this.$router.push('/pre/report?id=' + this.id);
        }
        this.disabled = false;
        this.$message.error(res.msg);
      }).catch(err => {
        console.log(err);
        this.disabled = false;
      })
    },
    reSection (type = 'front') {
      let node = type == 'front' ? this.$refs.frontBottomLineCenter.getNode() : this.$refs.sideBottomLineCenter.getNode();
      let points = node.points();
      let section = type == 'front' ? this.$refs.frontSection.getNode() : this.$refs.sideSection.getNode();
      section.x(this.getPointX(points[2], points[3], points[4], points[5], section.y()));
    },
    //获取点到线段的距离
    getDistFromPointToLine (pointX, pointY, line) {
      const equation = this.getLinearEquation(line[0], line[1], line[2], line[3]);
      let dist = Math.abs(equation[0] * pointX + equation[1] * pointY + equation[2]) / Math.sqrt(Math.pow(equation[0], 2) + Math.pow(equation[1], 2));
      return Math.floor(dist / this.base[0].proportion * 100) / 100;
    },
    getPointOfIntersection (points, tool) {
      return this.getPointX(points[2] + tool.x(), points[3] + tool.y(), points[4] + tool.x(), points[5] + tool.y(), this.dist);
    },
    //获取直线斜率
    getLinesSlope (x1, y1, x2, y2) {
      return (y2 - y1) / (x2 - x1);
    },
    //获取直线夹
    getLinesArctan (line1, line2) {
      if (line1 == Infinity && line2 == Infinity) {
        return 0;
      } else if (line1 == Infinity) {
        let tan = Math.atan(line2);
        let angle = Math.abs(tan * 180 / Math.PI);
        return 90 - angle;
      } else if (line2 == Infinity) {
        let tan = Math.atan(line1);
        let angle = Math.abs(tan * 180 / Math.PI);
        return 90 - angle;
      }
      let tan_k = 0; //直线夹角正切值
      let lines_arctan;//直线斜率的反正切值
      tan_k = (line2 - line1) / (1 + line2 * line1); //求直线夹角的公式
      lines_arctan = Math.abs(Math.atan(tan_k) * 180.0 / Math.PI);
      return lines_arctan;
    },

    // getRotation(line1){
    //     let tan_k = 0;
    //     tan_k =  -line1; 
    //     return Math.atan(tan_k) * 180.0 / Math.PI;
    // },

    //求直线方程式
    getLinearEquation (first_x, first_y, second_x, second_y) {
      //一般式 Ax+By+C=0
      let A = second_y - first_y;
      let B = first_x - second_x;
      let C = second_x * first_y - first_x * second_y;
      return [A, B, C]
    },
    //求交点
    getIntersectPointofLines (x1, y1, x2, y2, x3, y3, x4, y4) {
      let K1 = this.getLinearEquation(x1, y1, x2, y2);
      let A1 = K1[0];
      let B1 = K1[1];
      let C1 = K1[2];
      let K2 = this.getLinearEquation(x3, y3, x4, y4);
      let A2 = K2[0];
      let B2 = K2[1];
      let C2 = K2[2];
      let m = A1 * B2 - A2 * B1
      if (m == 0) {
        //平行，无交点
        return null;
      } else {
        let x = (C2 * B1 - C1 * B2) / m
        let y = (C1 * A2 - C2 * A1) / m
        return [x, y]
      }


    },
    //退出登录
    logout () {
      this.$confirm('是否退出登录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.logout().then(res => {
          if (res.code) {
            return this.$router.push('/login')
          }
          this.$message.error(res.msg);
        }).catch(err => {
          // this.$message.error('系统繁忙');
        })
      }).catch(() => {

      });
    },
    //返回上一步
    lastStep () {
      this.$router.push('/pre/measure?id=' + this.id);
    },
    frontTopRingMouseEnter (event) { //左侧绿色粗实线
      console.log('frontTopRingMouseEnter')
      this.popoverContent = this.$t('popver.jdh')
      this.popoverPosition.top = (+event.evt.screenY - 122) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 62) + 'px'
      this.popoverShow = true
    },
    frontTopRingMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    frontBottomRingMouseEnter (event) { // 左侧黄色粗实线
      console.log('frontBottomRingMouseEnter')
      this.popoverContent = this.$t('popver.ydh')
      this.popoverPosition.top = (+event.evt.screenY - 122) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 42) + 'px'
      this.popoverShow = true
    },
    frontBottomRingMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    frontBottomDotMouseEnter (event) { // 左侧黄色粗实线白色点
      console.log('frontBottomDotMouseEnter')
      this.popoverContent = this.$t('popver.ydhzd')
      this.popoverPosition.top = (+event.evt.screenY - 122) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 42) + 'px'
      this.popoverShow = true
    },
    frontBottomDotMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    frontTopDotMouseEnter (event) { // 左侧绿色粗实线白色点
      console.log('frontTopDotMouseEnter')
      this.popoverContent = this.$t('popver.jdhzd')
      this.popoverPosition.top = (+event.evt.screenY - 122) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 40) + 'px'
      this.popoverShow = true
    },
    frontTopDotMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    }
  }
};
</script>
<style scoped>
.warp {
  width: 1440px;
}

.el-main {
  padding: 0 20px;
  background: #f1f5f7;
}

.head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-header {
  background: #ffffff;
  text-align: right;
}

.btn-logout {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}

.btn-logout::before {
  content: "";
  background: url(../../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}

.btn-logout:hover {
  cursor: pointer;
}


.text-upload {
  margin: 17px 0;
  text-align: center;
  color: #7ECDF6;
  text-decoration: underline;
  cursor: pointer;
}

.main {
  /* height: ; */
  display: flex;
  justify-content: space-between;
}

.main .canvas {
  width: 76.88%;
  /* height: 66.94%; */
  height: 540px;
  border-radius: 4px;
  background: #000000;

}

.main .right-side,
.main .left-side {
  width: 16%;
  height: 540px;
  background: #ffffff;
  border-radius: 4px;
  overflow-y: auto;
}

.right-side {
  padding: 0;
  background: #f1f5f7 !important;
}

.main .left-side {
  width: 13%;
}

.main .form {

  font-size: 13px;
  font-weight: 400;
  color: #000000;

}

.container {
  background: #000000;
  position: relative;
  margin: 0 5px;

}

.zoom {
  position: absolute;
  right: 30px;
  bottom: 26px;
  z-index: 99;
}

/* 
.main .btn-submit{
    position: absolute;
    bottom: 25px;
    left:50%;
    transform: translate(-50%,0);
} */
.step-list .item {
  background: #F1F5F7;
  border: 1px solid #364284;
  border-radius: 4px;
  color: #364284;
  margin-top: 14px;
  padding: 12px 22px;
}

.step-list .active {
  background: #364284;
  color: #FFFFFF;
}

.btn-submit,
.btn-comfirm,
.btn-revocation {
  width: 198px;
  height: 38px;
  line-height: 38px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 auto;
  color: #FFFFFF;
  background: #364284;
  border: 1px solid #364284;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.btn-revocation {
  background: #F1F5F7;
  border: 1px solid #364284;
  color: #364284;
  margin-top: 10px;
  margin-bottom: 50px;
}

.content-title {
  font-size: 14px;
  border-bottom: none;

}

.el-form-item {
  margin-bottom: 2px
}

.popover {
  padding: 10px;
  background-color: #fff;
  position: fixed;
  border-radius: 5px;
}

.popover::before {
  content: '';
  position: absolute;
  border: 8px solid;
  border-color: #ffffff transparent transparent transparent;
  right: 32px;
  top: 35px;
}
</style>
